/**
 * Licensed to the Apache Software Foundation (ASF) under one
 * or more contributor license agreements.  See the NOTICE file
 * distributed with this work for additional information
 * regarding copyright ownership.  The ASF licenses this file
 * to you under the Apache License, Version 2.0 (the
 * "License"); you may not use this file except in compliance
 * with the License.  You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 */
import React, { FC } from 'react';
import { useTheme } from '@superset-ui/core';

export const CheckboxChecked: FC<{ disabled?: boolean }> = ({ disabled }) => {
  const theme = useTheme();

  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16 0H2C0.89 0 0 0.9 0 2V16C0 17.1 0.89 18 2 18H16C17.11 18 18 17.1 18 16V2C18 0.9 17.11 0 16 0Z"
        fill={disabled ? '#ffe2cf' : theme.colors.primary.base}
      />
      <path
        d="M7 14L2 9L3.41 7.59L7 11.17L14.59 3.58L16 5L7 14Z"
        fill={disabled ? '#f5f5f5' : 'white'}
      />
    </svg>
  );
};

export const CheckboxHalfChecked: FC<{ disabled?: boolean }> = ({
  disabled,
}) => {
  const theme = useTheme();
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16 0H2C0.9 0 0 0.9 0 2V16C0 17.1 0.9 18 2 18H16C17.1 18 18 17.1 18 16V2C18 0.9 17.1 0 16 0Z"
        fill={disabled ? '#ffe2cf' : theme.colors.grayscale.light1}
      />
      <path d="M14 10H4V8H14V10Z" fill={disabled ? '#f5f5f5' : 'white'} />
    </svg>
  );
};

export const CheckboxUnchecked: FC<{ disabled?: boolean }> = ({ disabled }) => {
  const theme = useTheme();
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16 0H2C0.9 0 0 0.9 0 2V16C0 17.1 0.9 18 2 18H16C17.1 18 18 17.1 18 16V2C18 0.9 17.1 0 16 0Z"
        fill={theme.colors.grayscale.light2}
      />
      <path d="M16 2V16H2V2H16V2Z" fill={disabled ? '#f5f5f5' : 'white'} />
    </svg>
  );
};
